import React from "react";
import { Typography } from "@material-tailwind/react";

export default function SkeletonTest() {
  return (
    <div className="relative shadow-md sm:rounded-lg my-5 lg:block overflow-x-auto">
      <table className="w-full min-w-[1000px] text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 table-fixed">
        <thead className="text-base text-white bg-blue-500">
          <tr>
            <th className="px-6 py-3 w-1/4">
              <div className="h-4 w-3/4 mx-auto rounded-full bg-gray-300 animate-pulse"></div>
            </th>
            <th className="px-6 py-3 w-1/4">
              <div className="h-4 w-3/4 mx-auto rounded-full bg-gray-300 animate-pulse"></div>
            </th>
            <th className="px-6 py-3 w-1/2">
              <div className="h-4 w-3/4 mx-auto rounded-full bg-gray-300 animate-pulse"></div>
            </th>
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: 10 }).map((_, index) => (
            <tr key={index} className="border-b border-gray-200">
              <td className="px-6 py-4 w-1/4">
                <div className="h-3 w-3/4 mx-auto rounded-full bg-gray-300 animate-pulse"></div>
              </td>
              <td className="px-6 py-4 w-1/4">
                <div className="h-3 w-3/4 mx-auto rounded-full bg-gray-300 animate-pulse"></div>
              </td>
              <td className="px-6 py-4 w-1/2">
                <div className="h-3 w-full mx-auto rounded-full bg-gray-300 animate-pulse"></div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
