import { useEffect, useState } from "react";
import { MdSpaceDashboard } from "react-icons/md";
import axios from "axios";
import Link from "next/link";
import Image from "next/image";
import { useDispatch } from "react-redux";
import { setPopularAreas } from "@/globalStates/dataSlice";
import SkeletonTest from "../common/skeletonTest";
import {
  countApi,
  getPopularAreas,
  getAreaBusiness,
} from "../../../utils/apiRoute";
import { Card } from "@material-tailwind/react";
import { TooltipWithHelperIcon } from "../common/tooltip";
import React from "react";
import { getLocals } from "../../../getLocal";
import { hostNameFunction } from "../../../utils/host-function";

export default function PopularAreas({ countryName, countryTranslations }) {
  const hostname = hostNameFunction();
  const dispatch = useDispatch();
  const [areasData, setAreasData] = useState([]);
  const [showCard, setShowCard] = useState({});
  const [localizedStrings, setLocalizedStrings] = useState();
  const [areaCount, setAreaCount] = useState([]);
  let formattedCountryName = countryName
    ? countryName.toLowerCase().replace(/ /g, "-")
    : "default-country"; // Provide a default value

  useEffect(() => {
    const fetchLocalizedStrings = async () => {
      const lang = hostname ? hostname : "en";
      const strings = await getLocals(lang);
      setLocalizedStrings(strings);
    };

    fetchLocalizedStrings();
  }, []);

  useEffect(() => {
    async function getDetails() {
      try {
        // setLoader(true);
        const response = await axios.post(getAreaBusiness, { lan: hostname });

        if (response.data.length > 0) {
          setAreasData(response.data);
          dispatch(setPopularAreas(response.data));
        }
        // setLoader(false);
      } catch (error) {
        // setLoader(false);
        console.error("Error");
      }
    }

    if (areasData.length == 0) {
      getDetails();
    } else {
      // setLoader(false);
    }
  }, []);

  useEffect(() => {
    async function getAreaCount() {
      try {
        const res = await axios.post(countApi);
        setAreaCount(res.data);
      } catch (error) {
        console.error("Error fetching area count:", error);
      }
    }

    getAreaCount();
  }, []); // The empty array ensures this runs only once after the initial render

  const handleMouseOver = (businessSlug, details) => {
    setShowCard((prevState) => ({
      // ...prevState,
      [businessSlug]: true, // Set the state for the specific link to show the card
    }));
  };

  const handleMouseLeave = (businessSlug) => {
    setShowCard((prevState) => ({
      // ...prevState,
      [businessSlug]: false, // Set the state for the specific link to hide the card
    }));
  };

  return (
    <>
      <div className="text-justify">
        <p className="pb-2 md:text-[1.17em] text-xl">
          {hostname == "hi" ? (
            <b>
              {countryTranslations?.[0]}{" "}
              {localizedStrings && localizedStrings.Index.categoryFirstLine4}{" "}
              {localizedStrings && localizedStrings.Index.areaHeading}
            </b>
          ) : (
            <b>
              {localizedStrings && localizedStrings.Index.areaHeading}{" "}
              {localizedStrings && localizedStrings.Index.categoryFirstLine4}{" "}
              {countryTranslations?.[0]}
            </b>
          )}
        </p>

        <p className="md:text-lg pb-1 md:leading-0 text-base leading-7 break-words w-full my-4 text-justify">
          {hostname == "hi" ? (
            <span>
              {countryTranslations?.[1]}{" "}
              {localizedStrings && localizedStrings.Index.areaFirstLine1},{" "}
              {localizedStrings && localizedStrings.Index.categoryFirstLine1}{" "}
              {countryTranslations?.[0]}{" "}
              {localizedStrings && localizedStrings.Index.categoryFirstLine4}{" "}
              {areaCount[0]?.totalAreas}{" "}
              {localizedStrings && localizedStrings.Index.areaFirstLine3}{" "}
              {localizedStrings && localizedStrings.Index.areaFirstLine2}{" "}
              {localizedStrings && localizedStrings.Index.categoryFirstLine2}{" "}
              {countryTranslations?.[0]}{" "}
              {localizedStrings && localizedStrings.Index.categoryFirstLine4}{" "}
              {localizedStrings && localizedStrings.Index.areaSecondLine1}{" "}
              {localizedStrings && localizedStrings.Index.areaSecondLine2}-{" "}
            </span>
          ) : (
            <span>
              {localizedStrings && localizedStrings.Index.areaFirstLine1}{" "}
              {countryTranslations?.[1]},{" "}
              {localizedStrings && localizedStrings.Index.categoryFirstLine1}{" "}
              {localizedStrings && localizedStrings.Index.categoryFirstLine2}{" "}
              {localizedStrings && localizedStrings.Index.areaFirstLine2}{" "}
              {areaCount[0]?.totalAreas}{" "}
              {localizedStrings && localizedStrings.Index.areaFirstLine3}{" "}
              {localizedStrings && localizedStrings.Index.categoryFirstLine4}{" "}
              {countryTranslations?.[0]}.{" "}
              {localizedStrings && localizedStrings.Index.areaSecondLine1}{" "}
              {localizedStrings && localizedStrings.Index.categoryFirstLine4}{" "}
              {countryTranslations?.[0]}{" "}
              {localizedStrings && localizedStrings.Index.areaSecondLine2}-{" "}
            </span>
          )}
          {areasData.length > 0 &&
            areasData.map((area, index) => (
              <React.Fragment key={index}>
                <span>{area.areaName}</span>
                {index !== areasData.length - 1 && <span>, </span>}
              </React.Fragment>
            ))}
          {hostname == "hi" ? "।" : "."}{" "}
          {hostname == "hi" ? (
            <span>
              {localizedStrings && localizedStrings.Index.areaThirdLine1}{" "}
              {countryTranslations?.[0]}{" "}
              {localizedStrings && localizedStrings.Index.categoryFirstLine4}{" "}
              {areaCount[0]?.totalAreas}{" "}
              {localizedStrings && localizedStrings.Index.areaFirstLine2}{" "}
              {localizedStrings && localizedStrings.Index.areaThirdLine3}{" "}
              {localizedStrings && localizedStrings.Index.areaFirstLine3}{" "}
              {localizedStrings && localizedStrings.Index.areaThirdLine2}-{" "}
            </span>
          ) : (
            <span>
              {localizedStrings && localizedStrings.Index.areaThirdLine1}{" "}
              {localizedStrings && localizedStrings.Index.areaThirdLine2}{" "}
              {localizedStrings && localizedStrings.Index.areaThirdLine3}{" "}
              {localizedStrings && localizedStrings.Index.areaFirstLine2}{" "}
              {areaCount[0]?.totalAreas}{" "}
              {localizedStrings && localizedStrings.Index.areaFirstLine3}{" "}
              {localizedStrings && localizedStrings.Index.categoryFirstLine4}{" "}
              {countryTranslations?.[0]}{" "}
              {localizedStrings && localizedStrings.Index.areaThirdLine4}{" "}
            </span>
          )}
          {hostname == "hi" ? (
            <Link
              href={`/${formattedCountryName}/top-areas`}
              className="text-cyan-500"
            >
              {countryTranslations?.[0]}{" "}
              {localizedStrings && localizedStrings.Index.categoryFirstLine4}{" "}
              {localizedStrings && localizedStrings.Index.areaThirdLine5}
            </Link>
          ) : (
            <Link
              href={`/${formattedCountryName}/top-areas`}
              className="text-cyan-500"
            >
              {localizedStrings && localizedStrings.Index.areaThirdLine5}{" "}
              {localizedStrings && localizedStrings.Index.categoryFirstLine4}{" "}
              {countryTranslations?.[0]}
            </Link>
          )}
        </p>
      </div>
      {areasData.length === 0 ? (
        <SkeletonTest />
      ) : (
        <div className="relative shadow-md sm:rounded-lg my-5 lg:block overflow-auto">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-base text-white bg-blue-500 hidden md:table-header-group">
              <tr>
                <th scope="col" className="px-6 py-3">
                  {localizedStrings && localizedStrings.Index.areaFirstColumn}
                </th>
                <th scope="col" className="px-6 py-3 ">
                  {localizedStrings && localizedStrings.Index.secondColumn}
                </th>
                <th scope="col" className="px-6 py-3">
                  {localizedStrings && localizedStrings.Index.thirdPlaces}
                </th>
              </tr>
            </thead>
            <tbody>
              {areasData.length > 0 &&
                areasData.slice(0, 10).map((area, index) => {
                  return (
                    <tr
                      className="bg-white hover:bg-lime-100 hover:border-2 hover:border-blue-400 flex md:table-row flex-wrap justify-between"
                      key={index}
                    >
                      <th
                        scope="row"
                        className="hidden md:table-cell px-6 py-4 font-medium whitespace-nowrap text-white bg-blue-500 md:bg-transparent "
                      >
                        <div className="flex">
                          <Image
                            src="/assets/images/placeholder.png"
                            className="h-12 w-12"
                            height={100}
                            width={100}
                            alt="image"
                          />
                          <Link
                            className="text-lg text-white md:text-blue-500  pr-1 pl-2"
                            href={`/area/${area.areaSlug}`}
                          >
                            {area.areaName}
                          </Link>
                        </div>
                      </th>

                      <td className="hidden md:table-cell px-6 py-4 text-xl text-white md:text-gray-800  bg-blue-500 md:bg-transparent">
                        {area.businessCount.toLocaleString()}
                      </td>

                      {/* for the mobile device only start */}
                      <td className="flex flex-wrap justify-between bg-blue-500  items-center px-4 md:hidden w-[100%]">
                        <div className="flex w-3/5 border-r-2 py-2 items-center">
                          <Image
                            src="/assets/images/placeholder.png"
                            className="h-8 w-8"
                            height={500}
                            width={500}
                            alt="image"
                          />
                          <Link
                            className="text-lg text-white md:text-blue-500 pr-1 pl-2"
                            href={`/area/${area.areaSlug}`}
                          >
                            {area.areaName}
                          </Link>
                        </div>

                        <div className="w-2/5 text-[1.2em] md:text-lg text-end text-white py-2">
                          {area.businessCount.toLocaleString()}
                        </div>
                      </td>
                      {/* for the mobile device only end */}

                      <td className="p-2 md:space-y-0">
                        {area.firstFiveBusinesses.length > 0 &&
                          area.firstFiveBusinesses.map(
                            (businessDetails, index) => (
                              <div
                                className="flex items-baseline md:items-center"
                                key={index}
                              >
                                <div className="pr-2">
                                  <MdSpaceDashboard className="text-[1.2em] md:text-lg" />
                                </div>
                                <div className="pr-2 relative leading-[2.2em] md:leading-10">
                                  <Link
                                    href={`/places/${businessDetails.businessSlug}`}
                                    className="text-lg text-blue-500 pr-1"
                                    onMouseOver={() =>
                                      handleMouseOver(
                                        businessDetails.businessSlug,
                                        businessDetails
                                      )
                                    }
                                  >
                                    <b className="text-base md:text-lg">
                                      {businessDetails.businessName}
                                    </b>
                                  </Link>
                                  {showCard[businessDetails.businessSlug] && (
                                    <Card
                                      className={`mt-6 w-96 absolute right-24 top-0 z-50 rounded-sm`}
                                      onMouseLeave={() =>
                                        handleMouseLeave(
                                          businessDetails.businessSlug
                                        )
                                      }
                                    >
                                      <TooltipWithHelperIcon
                                        props={businessDetails}
                                      />
                                    </Card>
                                  )}
                                  <span className="text-[1.2em] md:text-lg">
                                    {businessDetails.address}
                                  </span>
                                </div>
                              </div>
                            )
                          )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}

      <div className="flex justify-center pt-6">
        {hostname == "hi" ? (
          <Link href={`/${formattedCountryName}/top-areas`}>
            <button
              type="button"
              className="text-white bg-[#0d6efd] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300
             rounded-lg md:px-5 p-5 md:py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none 
             dark:focus:ring-blue-800 md:text-xl text-base pt-1 pb-1 font-bold"
            >
              {countryTranslations?.[0]}{" "}
              {localizedStrings && localizedStrings.Index.areaExploreBtn1}{" "}
              {localizedStrings && localizedStrings.Index.areaExploreBtn}
            </button>
          </Link>
        ) : (
          <Link href={`/${formattedCountryName}/top-areas`}>
            <button
              type="button"
              className="text-white bg-[#0d6efd] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300
             rounded-lg md:px-5 p-5 md:py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none 
             dark:focus:ring-blue-800 md:text-xl text-base pt-1 pb-1 font-bold"
            >
              {localizedStrings && localizedStrings.Index.areaExploreBtn}{" "}
              {localizedStrings && localizedStrings.Index.areaExploreBtn1}{" "}
              {countryTranslations?.[0]}
            </button>
          </Link>
        )}
      </div>
    </>
  );
}
