let HOST;
const API_PORT = process.env.API_PORT || "5000"; // Use API_PORT from environment variable or default to 5000

if (typeof window !== "undefined") {
  const currentUrl = window.location.origin;
  // Replace the port number in the current URL with the value of API_PORT
  const modifiedUrl = currentUrl.replace(/:\d+$/, `:${API_PORT}`);
  HOST = modifiedUrl;
} else {
  // Use localhost with API_PORT if on the server side (Node.js)
  HOST = `http://localhost:${API_PORT}`;
}

const apiRoute = `${HOST}/api`;
export const categoryApi = `${apiRoute}/getCategoryApi`;
export const CategoryData = `${apiRoute}/getCategoryData`;
export const ContactApi = `${apiRoute}/getContactApi`;
export const exploreData = `${apiRoute}/getExploreApi`;
export const getBusinessApi = `${apiRoute}/getBusinessApi`;
export const getCategoryApi = `${apiRoute}/getCategoryApi `;
export const getPopularAreas = `${apiRoute}/getPopularAreasApi`;
export const getReviewApi = `${apiRoute}/getReviewApi`;
export const getCategoryName = `${apiRoute}/getCategoryName`;
export const getAreaName = `${apiRoute}/getAreaName`;
export const reviewApi = `${apiRoute}/reviewApi`;
export const deleteApi = `${apiRoute}/getDeleteApi`;
export const addApi = `${apiRoute}/getAddApi`;
export const editApi = `${apiRoute}/getEditApi`;
export const categoryNewApi = `${apiRoute}/categoryApi`;
export const countApi = `${apiRoute}/getCountApi`;
export const getAreaTitle = `${apiRoute}/getAreaTitle`;
export const getCategoryTitle = `${apiRoute}/getCategoryTitle`;
export const getAreaBusiness = `${apiRoute}/getAreaBusinessApi`;
export const getBusinessApiDetails = `${apiRoute}/getBusinessApiDetails`;
