import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Reviews from "@/components/common/Reviews";
import CategoryListing from "@/components/home/CategoryListing";
import PopularCategory from "@/components/home/PopularAreas";
import Image from "next/image";
import { hostNameFunction } from "../../utils/host-function";
import Layout from "@/components/common/Layout";
import COUNTRY_NAME from "../locals/countryName.json";
import translations from "../locals/countryTranslation.json";
import { getLocals } from "../../getLocal";

export default function Index({
  obj,
  hostname,
  countryName,
  translations,
  localizedStrings,
}) {
  const settings = {
    rows: 1,
    slidesPerRow: 1,
    slidesToShow: 1,
    infinite: false,
    swipeToSlide: true,
    slidesToScroll: 1,
    lazyLoad: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const fontSize = "text-[1.17em]";
  let pageTitle = "";
  let description = "";
  let metaDescription = "";
  if (hostname == "hi") {
    pageTitle = `${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${localizedStrings && localizedStrings.Index.Title.homeTitleWord2}, ${
      localizedStrings && localizedStrings.Index.Title.homeTitleWord3
    }, ${localizedStrings && localizedStrings.Index.Title.homeTitleWord4}, ${
      localizedStrings && localizedStrings.Index.Title.homeTitleWord5
    }, ${localizedStrings && localizedStrings.Index.Title.homeTitleWord6}, ${
      localizedStrings && localizedStrings.Index.Title.homeTitleWord7
    } ${localizedStrings && localizedStrings.Index.Area.areaThirdLine2} ${
      localizedStrings && localizedStrings.Index.Title.homeTitleWord8
    } ${localizedStrings && localizedStrings.Index.Title.homeTitleWord1}- ${
      translations[1]
    }`;
  } else {
    pageTitle = `${
      localizedStrings && localizedStrings.Index.Title.homeTitleWord1
    } ${localizedStrings && localizedStrings.Index.Title.homeTitleWord2}, ${
      localizedStrings && localizedStrings.Index.Title.homeTitleWord3
    }, ${localizedStrings && localizedStrings.Index.Title.homeTitleWord4}, ${
      localizedStrings && localizedStrings.Index.Title.homeTitleWord5
    }, ${localizedStrings && localizedStrings.Index.Title.homeTitleWord6}, ${
      localizedStrings && localizedStrings.Index.Title.homeTitleWord7
    } ${localizedStrings && localizedStrings.Index.Area.areaThirdLine2} ${
      localizedStrings && localizedStrings.Index.Title.homeTitleWord8
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    } - ${translations[1]}`;
  }

  if (hostname == "hi") {
    description = ` ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord1
    }, ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${localizedStrings && localizedStrings.Index.Title.homeTitleWord6}, ${
      translations[0]
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord2
    }, ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord3
    }, ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord4
    }, ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${localizedStrings && localizedStrings.Index.Title.homeTitleWord2}, ${
      translations[0]
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord5
    }, ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord6
    }, ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord7
    }, ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord8
    }, ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord9
    }, ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord10
    }, ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord11
    }, ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord12
    }, ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord13
    }, ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord14
    }, ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord15
    }, ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord16
    }, ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord17
    }, ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${localizedStrings && localizedStrings.Index.Title.homeTitleWord7}, ${
      translations[0]
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord18
    }, ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord19
    }, ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord20
    }, ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord21
    }, ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord22
    }, ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${localizedStrings && localizedStrings.Index.Title.homeTitleWord3}, ${
      translations[0]
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord26
    }, ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord27
    }, ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord28
    }, ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${localizedStrings && localizedStrings.Index.Title.homeTitleWord5}, ${
      translations[0]
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord29
    }, ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord30
    }, ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord31
    }, ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord32
    }, ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord33
    }, ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord34
    }, ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord35
    }, ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord36
    }, ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord37
    }, ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord38
    }, ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord39
    }, ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord40
    }, ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord41
    }, ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord42
    }, ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord43
    }, ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord44
    }, ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord45
    }, ${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord46
    }, ${translations[0]} ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord47
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4}`;
  } else {
    description = `${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord1
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }, ${localizedStrings && localizedStrings.Index.Title.homeTitleWord6} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${translations[0]}, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord2
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord3
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord4
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }, ${localizedStrings && localizedStrings.Index.Title.homeTitleWord2} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${translations[0]}, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord5
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord6
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord7
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord8
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord9
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4}${
      translations[0]
    }, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord10
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord11
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord12
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord13
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord14
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord15
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord16
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord17
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }, ${localizedStrings && localizedStrings.Index.Title.homeTitleWord7} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${translations[0]}, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord18
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord19
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord20
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord21
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord22
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }, ${localizedStrings && localizedStrings.Index.Title.homeTitleWord3} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${translations[0]}, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord26
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord27
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord28
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }, ${localizedStrings && localizedStrings.Index.Title.homeTitleWord5} ${
      localizedStrings && localizedStrings.Index.categoryFirstLine4
    } ${translations[0]}, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord29
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord30
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord31
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord32
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord33
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord34
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord35
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord36
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord37
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord38
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord39
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord40
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord41
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord42
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord43
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord44
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord45
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord46
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }, ${
      localizedStrings &&
      localizedStrings.Index.Description.homeDescriptionWord47
    } ${localizedStrings && localizedStrings.Index.categoryFirstLine4} ${
      translations[0]
    }`;
  }

  if (hostname == "hi") {
    metaDescription = `${translations[0]} ${
      localizedStrings && localizedStrings.Index.categoryHeading1
    }- ${description}`;
  } else {
    metaDescription = `${
      localizedStrings && localizedStrings.Index.categoryHeading1
    } ${translations[0]}- ${description}`;
  }

  let metaKeywords = description;

  return (
    <Layout
      obj={obj}
      hostname={hostname}
      pageTitle={pageTitle}
      metaDescription={metaDescription}
      metaKeywords={metaKeywords}
      countryName={countryName}
      countryTranslations={translations}
    >
      <div className="p-2 md:p-10">
        <div className="flex">
          <img
            src="/assets/img/banners/desktop/slide-1.webp" // Use only one image
            alt="Slide 1"
            className="h-[400px] object-cover w-full"
          />
        </div>

        <div>
          {/* for desktop view */}
          <CategoryListing
            countryName={countryName}
            countryTranslations={translations}
          />
        </div>

        {/* <div className=" flex items-center justify-center w-full h-[90px]"></div> */}

        <div>
          <PopularCategory
            countryName={countryName}
            countryTranslations={translations}
          />
        </div>

        {/* <div className=" flex items-center justify-center w-full h-[90px]"></div> */}

        <div>
          <Reviews fontSize={fontSize} />
        </div>
      </div>
    </Layout>
  );
}

export async function getServerSideProps(context) {
  const { req, resolvedUrl } = context;
  const pro = req.headers["x-forward-proto"] || "https";
  const h = req.headers["host"];
  const full = `${pro}://${h}${resolvedUrl}`;
  const getHttp = process.env.BASE_URL;
  const currentUrl = `${getHttp}://${context.req.headers.host}${context.req.url}`;
  const hostname = hostNameFunction(context.req);

  // Get base URL by removing subdomains
  let baseUrl = currentUrl.split(".com")[0] + ".com"; // Extract base URL before `.com`

  // Extract what comes after `https://`
  let domainPart = baseUrl.replace(/^https?:\/\//, "");

  // Check for `hostname.` or `hostname-` at the start of the domainPart
  if (domainPart.startsWith(`${hostname}.`)) {
    // Replace language subdomain with "www."
    baseUrl = baseUrl.replace(
      new RegExp(`^https?://${hostname}[.]`),
      "https://www."
    );
  } else if (domainPart.startsWith(`${hostname}-`)) {
    // Remove language prefix and hyphen
    baseUrl = baseUrl.replace(new RegExp(`^https?://${hostname}-`), "https://");
  }
  // Determine the country from the base URL or default to 'Unknown Country'
  const country = COUNTRY_NAME[baseUrl] || "Unknown Country";
  // Get the language or default to 'en'
  const language = hostname.split(".")[0] || "en"; // Default to 'en' if no subdomain or 'www'

  // Fetch the translations for the determined country and language
  const countryTranslations =
    translations[country]?.[language] || translations[country]?.["en"] || {};
  const localizedStrings = await getLocals(hostname);
  return {
    props: {
      obj: full,
      hostname: hostname,
      translations: countryTranslations,
      localizedStrings: localizedStrings,
    },
  };
}
